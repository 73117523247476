import React from 'react'
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Input,
  Progress,
  Space,
  Divider,
} from 'antd'
const Amigos = () => {
  return (
    <div>
      <Card
        bordered={false}
        style={{
          borderRadius: 20,
          background: 'white',

          marginLeft: window.innerWidth > 600 ? 50 : 0,
          marginRight: window.innerWidth > 600 ? 50 : 0,
          marginTop: window.innerWidth > 600 ? 20 : 0,
          minWidth: 360,
          boxShadow: 'rgba(0, 0, 0, 0.3) 0px 4px 12px',
        }}
      >
        <Row>
          <Col span={window.innerWidth > 600 ? 18 : 24}>
            <div
              style={{
                marginLeft: 10,
                paddingLeft: 10,
                textAlign: 'left',
              }}
            >
              <h1>ElConejo.Info</h1>
              <p>
                Es un sitio que brinda herramientas como el buscador de remedios
                o el mapa de perros perdidos. Este es un proyecto sin fines de
                lucro, que requiere el apoyo tanto de particulares como
                privados, para mantenerse actualizado y en correcto
                funcionamiento.
              </p>
              <p>
                Puedes aportar difundiendo el sitio{' '}
                <strong>
                  <a href="https://elconejo.info">https://elconejo.info</a>{' '}
                </strong>{' '}
                en redes sociales y nuestra cuenta de{' '}
                <strong>
                  Twitter <a href="https://twitter.com/infonejo">@infonejo</a>{' '}
                </strong>
                .
              </p>
              <p>
                Si tienes una clínica o tienda de mascotas puedes mostrar
                nuestros display informativos y nosotros incluiremos tu logo y
                link en nuestra sección de marcas amigas.{' '}
              </p>
              <p>
                Si deseas aportar con dinero para cumplir con la meta mensual,
                te ofrecemos estas opciones:
              </p>
              <Row>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 10,
                  }}
                >
                  <strong>Banco</strong>
                  <small>Cuenta Corriente</small>
                  <small>Banco Estado</small>
                  <small>Ivan Mery</small>
                  <small>5355626</small>
                  <small>8.218.429-5</small>
                  <small>steelgrafica@gmail.com</small>
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 10,
                  }}
                >
                  <strong>Crypto</strong>
                  <small>Address (ERC20)</small>
                  <small>0x8151b1055198D46BDdAb703052e6Be6b13e743E5</small>
                </Col>
              </Row>
              <p>
                La meta mensual es un valor promedio que cubre los gastos
                logísticos (Material físico y digital informativo) y servicios
                otorgados por Google (Mapa, Base de datos, Storage).
              </p>
              <p> ElConejo.Info no cuenta con empleados solo voluntarios.</p>
              <p>Dudas?</p>
              <p>📧 hexvanilla@gmail.com</p>
              <h2>Marcas & Clinicas Amigas</h2>
            </div>
          </Col>
          <Col>
            <div
              style={{
                marginLeft: 10,
                paddingLeft: 10,
                textAlign: 'center',
              }}
            >
              {' '}
              <h1>Meta Mensual</h1>
              <p>Febrero</p>
              <Space wrap>
                <Progress type="dashboard" percent={0} />
              </Space>{' '}
              <h2>$120.000</h2>
            </div>
          </Col>
          <Divider />
        </Row>
      </Card>
    </div>
  )
}

export default Amigos
