import React, { useState, useCallback, useEffect } from 'react'

import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from '@react-google-maps/api'
import dog_placeholder from '../imgs/dog_placeholder.jpg'
import paw from '../imgs/paw.png'
import pawBlue from '../imgs/pawBlue.png'
import { PlusOutlined } from '@ant-design/icons'
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Input,
  DatePicker,
  Upload,
  Radio,
} from 'antd'
import { initializeApp } from 'firebase/app'
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'
import { getDatabase, set, onValue, remove } from 'firebase/database'
import { ref as dbRef } from 'firebase/database'
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_key,
  authDomain: 'hextoolsperdidos.firebaseapp.com',
  projectId: 'hextoolsperdidos',
  storageBucket: 'hextoolsperdidos.appspot.com',
  databaseURL: 'https://hextoolsperdidos-default-rtdb.firebaseio.com',
  messagingSenderId: '906699436229',
  appId: '1:906699436229:web:90a011162ba0a0db70c0ac',
  measurementId: 'G-50X64PB86Z',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

const auth = getAuth(app)
const provider = new GoogleAuthProvider()

const storage = getStorage()
const db = getDatabase(app)

const containerStyle = {
  height: '100vh',
}

const center = {
  lat: -33.44,
  lng: -70.63,
}

const { TextArea } = Input

const LostDogs = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCMH4yuKb4IKBlyPkpVGP_mBVlXjCOlw7E',
  })
  const [lastKnownLocation, setLastKnownLocation] = useState('loading')
  const [map, setMap] = useState(null)
  const [xoom, setXoom] = useState(11)
  const [popUp, setPopUp] = useState('')
  const [newLocation, setNewLocation] = useState('')
  const [newLocationLatLng, setNewLocationLatLng] = useState('')
  const [image, setImage] = useState('')
  const [activeUser, setActiveUser] = useState('')
  const [userId, setUserId] = useState('')
  const [hasPublished, setHasPublished] = useState(false)
  const [formType, setFormType] = useState('')

  useEffect(() => {
    setXoom(12)
    readUserData()
  }, [hasPublished])

  useEffect(() => {
    if (lastKnownLocation == 'loading') return
    setHasPublished(lastKnownLocation.some((entry) => entry.Id == userId))
    console.log(userId)
  }, [activeUser])

  function signInWithGoogle() {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result)
        const token = credential.accessToken
        // The signed-in user info.
        const user = result.user

        setActiveUser(user.displayName)
        setUserId(user.uid)

        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        // The email of the user's account used.
        const email = error.customData.email
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error)
        // ...
      })
  }

  function signOutOfGoogle() {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        console.log('out')
        setActiveUser('')
      })
      .catch((error) => {
        // An error happened.
        console.log(error)
      })
  }

  const onFinish = (values) => {
    console.log('Success:', values)
    upload(values)
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  // 'file' comes from the Blob or File API
  const upload = (values) => {
    const storageRef = ref(storage, `users/${userId}/img_${userId}`)
    uploadBytes(storageRef, image).then((snapshot) => {
      getDownloadURL(ref(storage, `users/${userId}/img_${userId}`))
        .then((url) => {
          console.log('url', url)
          values.photo = url
          values.date = values.date.toString()
          values.loc = { lat: values.loc.lat(), lng: values.loc.lng() }
          writeUserData(values)
        })
        .catch((error) => {
          console.log(error)
          // Handle any errors
        })
    })
  }

  function writeUserData(values) {
    console.log('write')

    set(dbRef(db, `users/${userId}`), {
      fecha: values.date,
      nombrePerro: values.type == 'perdido' ? values.dogname : '',
      correo: values.email,
      phone: values.phone,
      loc: values.loc,
      nombreContacto: values.type == 'perdido' ? values.ownername : '',
      foto: values.photo,
      Id: userId,
      type: values.type,
      info: values.extraInfo == undefined ? '' : values.extraInfo,
    })

    setHasPublished(true)
  }
  function readUserData() {
    const arr = []
    const missingDogs = dbRef(db, 'users/')
    onValue(missingDogs, (snapshot) => {
      const data = snapshot.val()
      console.log('missingDogs', data)
      if (data == null) return
      Object.entries(data)?.map((entry) => arr.push(entry[1]))
      setLastKnownLocation([...arr])
    })
  }

  function removeUserData() {
    const dog = lastKnownLocation.filter((entry) => entry.Id == userId)
    console.log(dog[0].nombrePerro)
    remove(dbRef(db, 'users/' + userId))

    // Create a reference to the file to delete
    const toDelete = ref(storage, `users/${userId}/img_${userId}`)

    // Delete the file
    deleteObject(toDelete)
      .then(() => {
        // File deleted successfully
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      })

    setHasPublished(false)
    setLastKnownLocation('loading')
    readUserData()
  }

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center)
    //map.fitBounds(bounds)

    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const pinInfo = (dog) => {
    setPopUp(
      <InfoWindow onCloseClick={() => setPopUp('')} position={dog.loc}>
        <div>
          <img src={dog.foto} style={{ maxWidth: 480 }} alt="" />
          <h2>{dog.nombrePerro}</h2>
          <p>{dog.nombreContacto}</p>
          <p>{dog.correo}</p>
          <p>{dog.phone}</p>
          <p>{dog.fecha}</p>
        </div>
      </InfoWindow>
    )
  }

  const placeNewMarker = (loc) => {
    setNewLocation(<Marker position={loc} />)
    setNewLocationLatLng(loc)
    console.log(loc)
  }

  return (
    <div
      style={{
        marginLeft: window.innerWidth > 600 ? 50 : 0,
        marginRight: window.innerWidth > 600 ? 50 : 0,
        marginTop: window.innerWidth > 600 ? 20 : 0,
      }}
    >
      <Row
        style={{
          display: 'flex',
          justifyContent: window.innerWidth > 600 ? 'center' : 'inherit',
        }}
      >
        <Col span={window.innerWidth > 600 ? 18 : 24}>
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={xoom}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onClick={(e) => placeNewMarker(e.latLng)}
              options={{
                styles: [
                  {
                    elementType: 'labels',
                    featureType: 'poi.business',
                    stylers: [{ visibility: 'off' }],
                  },
                ],
              }}
            >
              {lastKnownLocation == 'loading'
                ? ''
                : lastKnownLocation.map((dog) => {
                    if (dog.type == 'perdido')
                      return (
                        <Marker
                          icon={paw}
                          title={`🐶${dog.nombrePerro}`}
                          position={dog.loc}
                          onClick={() => pinInfo(dog)}
                        />
                      )
                    if (dog.type == 'encontrado')
                      return (
                        <Marker
                          icon={pawBlue}
                          title={`🐶${dog.nombrePerro}`}
                          position={dog.loc}
                          onClick={() => pinInfo(dog)}
                        />
                      )
                  })}
              {popUp}
              {newLocation}
              <></>
            </GoogleMap>
          ) : (
            <></>
          )}
        </Col>
        <Col span={window.innerWidth > 600 ? 6 : 24}>
          <Card
            style={{ backgroundColor: 'whitesmoke' }}
            bordered={false}
            title={
              <div>
                <p>Leyenda</p>
                <Row>
                  <Col span={12}>
                    {' '}
                    <img src={paw} alt="perdidos" style={{ padding: 5 }} />
                    <p>Perdidos</p>
                  </Col>
                  <Col span={12}>
                    {' '}
                    <img
                      src={pawBlue}
                      alt="encontrados"
                      style={{ padding: 5 }}
                    />
                    <p>Encontrados*</p>
                  </Col>
                  <small>
                    *Perros encontrados en la calle, en busca de sus dueños.
                  </small>
                </Row>
              </div>
            }
          >
            <h2>{activeUser}</h2>
            {activeUser != '' ? (
              hasPublished ? (
                <div>
                  <Button onClick={() => signOutOfGoogle()}>Salir</Button>
                  <p>
                    Para Mantenera la data limpia y actualizada solo se permite
                    ingresar una mascota por cuenta.
                  </p>
                  <p>
                    Debes eliminar la entrada actual, si deseas ingresar una
                    nueva.
                  </p>
                  <Button
                    type="primary"
                    onClick={() => removeUserData()}
                    danger
                  >
                    Eliminar
                  </Button>
                </div>
              ) : (
                <div>
                  <Button onClick={() => signOutOfGoogle()}>Salir</Button>
                  <h2>Agregar al Mapa</h2>

                  {formType == 'perdido' ? (
                    <Form
                      name="basic"
                      layout="vertical"
                      labelCol={{
                        span: 16,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      fields={[
                        {
                          name: ['loc'],
                          value:
                            newLocationLatLng != '' ? newLocationLatLng : '',
                        },
                      ]}
                    >
                      <Form.Item
                        label="Asunto"
                        name="type"
                        rules={[
                          {
                            required: true,
                            message: 'Elige una Opcion!',
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Radio
                            value="perdido"
                            onClick={() => setFormType('perdido')}
                          >
                            {' '}
                            Perdi a mi Perro{' '}
                          </Radio>
                          <Radio
                            value="encontrado"
                            onClick={() => setFormType('encontrado')}
                          >
                            {' '}
                            Encontre a un Perro{' '}
                          </Radio>
                        </Radio.Group>
                      </Form.Item>{' '}
                      <Form.Item
                        label="Haz Click en el mapa para agregar Ubicacion"
                        name="loc"
                        rules={[
                          {
                            required: true,
                            message: 'Elige un lugar en el mapa!',
                          },
                        ]}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                      <Form.Item
                        label="Nombre del Perro/a"
                        name="dogname"
                        rules={[
                          {
                            required: true,
                            message: 'Ingresa el nombre de tu mascota!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Nombre del Dueño/a"
                        name="ownername"
                        rules={[
                          {
                            required: true,
                            message: 'Ingresa el nombre de contacto!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Telefono"
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: 'Introduce el telfono de contacto!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Correo"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: 'Introduce el correo de contacto!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Fecha"
                        name="date"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <DatePicker format={'YYYY-MM-DD'} />
                      </Form.Item>
                      <Form.Item
                        label="Foto"
                        valuePropName="fileList"
                        name="photo"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <center>
                          <input
                            type="file"
                            onChange={(e) => {
                              setImage(e.target.files[0])
                            }}
                          />
                        </center>
                      </Form.Item>
                      <Form.Item
                        label="Informacion Extra. Placa, Color de collar, etc..."
                        name="extraInfo"
                      >
                        <TextArea rows={4} />
                      </Form.Item>
                      <Form.Item
                        wrapperCol={{
                          span: 24,
                        }}
                      >
                        <Button type="primary" htmlType="submit">
                          Enviar
                        </Button>
                      </Form.Item>
                    </Form>
                  ) : (
                    <Form
                      name="basic"
                      layout="vertical"
                      labelCol={{
                        span: 16,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      fields={[
                        {
                          name: ['loc'],
                          value:
                            newLocationLatLng != '' ? newLocationLatLng : '',
                        },
                      ]}
                    >
                      <Form.Item
                        label="Asunto"
                        name="type"
                        rules={[
                          {
                            required: true,
                            message: 'Elige una Opcion!',
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Radio
                            value="perdido"
                            onClick={() => setFormType('perdido')}
                          >
                            {' '}
                            Perdi a mi Perro{' '}
                          </Radio>
                          <Radio
                            value="encontrado"
                            onClick={() => setFormType('encontrado')}
                          >
                            {' '}
                            Encontre a un Perro{' '}
                          </Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        label="Haz Click en el mapa para agregar Ubicacion"
                        name="loc"
                        rules={[
                          {
                            required: true,
                            message: 'Elige un lugar en el mapa!',
                          },
                        ]}
                      >
                        <Input disabled={true} />
                      </Form.Item>

                      <Form.Item
                        label="Telefono"
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: 'Introduce el telfono de contacto!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Correo"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: 'Introduce el correo de contacto!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Fecha"
                        name="date"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <DatePicker format={'DD-MM-YYYY'} />
                      </Form.Item>

                      <Form.Item
                        label="Foto"
                        valuePropName="fileList"
                        name="photo"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <center>
                          <input
                            type="file"
                            onChange={(e) => {
                              setImage(e.target.files[0])
                            }}
                          />
                        </center>
                      </Form.Item>
                      <Form.Item
                        label="Informacion Extra. Placa, Color de collar, Edad, etc..."
                        name="extraInfo"
                      >
                        <TextArea rows={4} />
                      </Form.Item>
                      <Form.Item
                        wrapperCol={{
                          span: 24,
                        }}
                      >
                        <Button type="primary" htmlType="submit">
                          Enviar
                        </Button>
                      </Form.Item>
                    </Form>
                  )}
                </div>
              )
            ) : (
              <div>
                <p>
                  {' '}
                  Ingresa Con tu Cuenta de Gmail para Agregar a un Perro perdido
                  al Mapa.
                </p>
                <Button type="primary" onClick={() => signInWithGoogle()}>
                  Ingresar con Goolge
                </Button>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default LostDogs
