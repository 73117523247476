import { useState, useEffect } from 'react'
import farmazon_data from '../scrapData/Drugs/v4/farmazon_df_data.json'
import ahumada_data from '../scrapData/Drugs/v4/ahumada_df_data.json'
import cruzverde_data from '../scrapData/Drugs/v4/cruzverde_df_data.json'
import ecofarmacias_data from '../scrapData/Drugs/v4/ecofarmacias_df_data.json'
import mercadofarma_data from '../scrapData/Drugs/v4/mercadofarma_df_data.json'
import salcobrand_data from '../scrapData/Drugs/v4/salcobrand_df_data.json'
import santa_g_data from '../scrapData/Drugs/v4/santa_g_df_data.json'
import redfarma_data from '../scrapData/Drugs/v4/redfarma_df_data.json'

import ahumada_logo from '../imgs/ahumada_logo.webp'
import cruzverde_logo from '../imgs/cruzverde_logo.svg'
import ecofarmacias_logo from '../imgs/ecofarmacias_logo.png'
import farmazon_logo from '../imgs/farmazon_logo.png'
import mercadofarma_logo from '../imgs/mercadofarma_logo.avif'
import salcobrand_logo from '../imgs/salcobrand_logo.svg'
import santagemita_logo from '../imgs/santagemita_logo.jpg'
import redfarma_logo from '../imgs/redfarma_logo.png'
import pill_1F48A from '../imgs/pill_1F48A.svg'
import BunnyCircle from '../imgs/BunnyCircle.png'
import bandage_1FA79 from '../imgs/bandage_1FA79.svg'

import { Row, Col, Card, Button, Form, Input, Avatar } from 'antd'
import { initializeApp } from 'firebase/app'
import { getDatabase, set, onValue, remove } from 'firebase/database'
import { ref as dbRef } from 'firebase/database'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_key,
  authDomain: 'hextoolsperdidos.firebaseapp.com',
  projectId: 'hextoolsperdidos',
  storageBucket: 'hextoolsperdidos.appspot.com',
  databaseURL: 'https://hextoolsperdidos-default-rtdb.firebaseio.com',
  messagingSenderId: '906699436229',
  appId: '1:906699436229:web:90a011162ba0a0db70c0ac',
  measurementId: 'G-50X64PB86Z',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

const db = getDatabase(app)

const Drugs = () => {
  const [dbData, setDbData] = useState('not-loaded')
  const [groups, setGroups] = useState([])

  useEffect(() => {
    readData()
  }, [])

  const drugsData = dbRef(db, 'drugs/')
  const arr = []
  const jsonArr = []
  const emptyGoup = []
  function readData() {
    onValue(drugsData, (snapshot) => {
      const data = snapshot.val()
      console.log('drugsData', Object.values(data))
      if (data == null) return
      Object.entries(data)?.map((entry) => arr.push(entry[1]))
      for (let i = 0; i < arr.length; i++) {
        jsonArr.push(JSON.parse(arr[i]))
      }

      for (let i = 0; i < jsonArr.length; i++) {
        const child = []
        Object.entries(jsonArr.at(i)).forEach((entry) => {
          child.push(entry.at(1))
        })
        emptyGoup.push(child)
      }
      setGroups([...emptyGoup])
      setDbData('Loaded')
    })
  }
  /*
  const farmazon_JSON = JSON.parse(farmazon_data)
  const ahumada_JSON = JSON.parse(ahumada_data)
  const cruzverde_JSON = JSON.parse(cruzverde_data)
  const ecofarmacias_JSON = JSON.parse(ecofarmacias_data)
  const mercadofarma_JSON = JSON.parse(mercadofarma_data)
  const salcobrand_JSON = JSON.parse(salcobrand_data)
  const santa_gemita_JSON = JSON.parse(santa_g_data)
  const redfarma_JSON = JSON.parse(redfarma_data)

  const farmazon_array = []
  const ahumada_array = []
  const cruzverde_array = []
  const ecofarmacias_array = []
  const mercadofarma_array = []
  const salcobrand_array = []
  const santa_gemita_array = []
  const redfarma_array = []
*/
  const [farmazonResults, setFarmazonResults] = useState([])
  const [ahumadaResults, setAhumadaResults] = useState([])
  const [cruzverdeResults, setCruzverdeResults] = useState([])
  const [ecofarmaciasResults, setEcofarmaciasResults] = useState([])
  const [mercadofarmaResults, setMercadofarmaResults] = useState([])
  const [salcobrandResults, setSalcobrandResults] = useState([])
  const [santa_gemitaResults, setSanta_gemitaResults] = useState([])
  const [redfarmaResults, setRedfarmaResults] = useState([])

  const [lowestPrice, setLowestPrice] = useState('')
  const [currentSearchTerm, setCurrentSearchTerm] = useState('')

  const chains = [
    {
      logo: farmazon_logo,
      aspect: 'horizontal',
      name: 'Farmazon',
      // jsonData: farmazon_JSON,
      arrayData: groups[1],
      results: farmazonResults,
      setResults: setFarmazonResults,
    },
    {
      logo: ahumada_logo,
      aspect: 'horizontal',
      name: 'Ahumada',
      //  jsonData: ahumada_JSON,
      arrayData: groups[0],
      results: ahumadaResults,
      setResults: setAhumadaResults,
    },
    {
      logo: cruzverde_logo,
      aspect: 'square',
      name: 'Cruz Verde',
      //  jsonData: cruzverde_JSON,
      arrayData: groups[4],
      results: cruzverdeResults,
      setResults: setCruzverdeResults,
    },
    {
      logo: ecofarmacias_logo,
      aspect: 'square',
      name: 'Ecofarmacias',
      // jsonData: ecofarmacias_JSON,
      arrayData: groups[2],
      results: ecofarmaciasResults,
      setResults: setEcofarmaciasResults,
    },
    {
      logo: mercadofarma_logo,
      aspect: 'horizontal',
      name: 'Mercadofarma',
      // jsonData: mercadofarma_JSON,
      arrayData: groups[7],
      results: mercadofarmaResults,
      setResults: setMercadofarmaResults,
    },
    {
      logo: salcobrand_logo,
      aspect: 'square',
      name: 'Salcobrand',
      //  jsonData: salcobrand_JSON,
      arrayData: groups[6],
      results: salcobrandResults,
      setResults: setSalcobrandResults,
    },
    {
      logo: santagemita_logo,
      aspect: 'square',
      name: 'Santa Gemita',
      //  jsonData: santa_gemita_JSON,
      arrayData: groups[3],
      results: santa_gemitaResults,
      setResults: setSanta_gemitaResults,
    },
    {
      logo: redfarma_logo,
      aspect: 'horizontal',
      name: 'Redfarma',
      //  jsonData: redfarma_JSON,
      arrayData: groups[5],
      results: redfarmaResults,
      setResults: setRedfarmaResults,
    },
  ]

  console.log(groups[6])
  /*
  for (let i = 0; i < chains.length; i++) {
    Object.entries(chains.at(i).jsonData).forEach((entry) => {
      chains.at(i).arrayData.push(entry.at(1))
    })
  }
*/
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  function searchDrugsDB(values) {
    const searchTerm = Object.values(values)
    setCurrentSearchTerm(searchTerm)

    if (searchTerm.at(0).length < 4) {
      return
    }
    const lowerCaseTerm = searchTerm.at(0).toLowerCase()

    for (let i = 0; i < chains.length; i++) {
      let arr = []
      chains.at(i).arrayData.forEach((drugs) => {
        if (drugs.Name.toLowerCase().includes(lowerCaseTerm) == true) {
          arr.push(drugs)
        }
      })
      chains.at(i).setResults(arr)
      console.log('arr', arr)
    }
    setLowestPrice('')
    //comparePrice();
  }

  function comparePrice() {
    if (currentSearchTerm == '') return
    const groupPrices = []
    chains.map((chain) =>
      chain.results?.map((product) => {
        if (product.Price.toString().replace(/\D/g, '') != 0)
          groupPrices.push(product.Price.toString().replace(/\D/g, ''))
      })
    )
    console.log('grp', groupPrices)
    setLowestPrice(groupPrices.sort((a, b) => a - b).at(0))
    console.log(
      '$' +
        new Intl.NumberFormat('es-CL').format(
          groupPrices.sort((a, b) => a - b).at(0)
        )
    )
  }

  useEffect(() => {
    if (lowestPrice != '') {
      document.getElementById('lowPrice').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      })
    }
  }, [lowestPrice])

  return (
    <div>
      <Card
        bordered={false}
        style={{
          borderRadius: 20,
          background: 'white',

          marginLeft: window.innerWidth > 600 ? 50 : 0,
          marginRight: window.innerWidth > 600 ? 50 : 0,
          marginTop: window.innerWidth > 600 ? 20 : 0,
          minWidth: 360,
          // boxShadow: 'rgba(0, 0, 0, 0.3) 0px 4px 12px',
        }}
      >
        <Col span={24}>
          <Form
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={searchDrugsDB}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item label="Buscar 💊" name="drugs">
              <Input typeof="text" />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                🔎
              </Button>
            </Form.Item>
          </Form>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <small>
              Para mejores resultados de búsqueda, busca términos más
              específicos. Prefiere{' '}
              <strong>paracetamol 500 mg o xumadol</strong> a solo{' '}
              <strong>paracetamol</strong>.
            </small>
            <Button
              type="primary"
              style={{ margin: 20, width: 320 }}
              onClick={() => comparePrice()}
            >
              Muestrame el Precio más bajo 💰
            </Button>
          </div>
        </Col>
        <Row gutter={16} style={{ justifyContent: 'center' }}>
          {dbData == 'Loaded' &&
            chains.map((chain) => (
              <Col>
                <Card
                  bordered={false}
                  headStyle={{ minHeight: 100 }}
                  style={{
                    maxWidth: 360,
                    minWidth: 180,
                    minHeight: 240,
                    background: 'white',
                    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 6px',
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                  title={
                    chain.aspect == 'horizontal' ? (
                      <img
                        src={chain.logo}
                        alt=""
                        style={{ width: 120, paddingTop: 10 }}
                      />
                    ) : (
                      <img
                        src={chain.logo}
                        alt=""
                        style={{ width: 60, paddingTop: 10 }}
                      />
                    )
                  }
                >
                  {dbData == 'Loaded' &&
                    chain.results?.map((result) => {
                      return (
                        <Row>
                          <Col>
                            {result.Price.toString().replace(/\D/g, '') ==
                            lowestPrice ? (
                              <Card
                                id="lowPrice"
                                onClick={() => window.open(result.Link)}
                                hoverable={true}
                                bordered={false}
                                style={{
                                  borderWidth: 2,
                                  borderColor: 'green',
                                  backgroundColor: 'lightgreen',
                                  marginBottom: 10,
                                  marginTop: 10,
                                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                                }}
                                headStyle={{
                                  fontSize: 'small',
                                }}
                                title={result.Name}
                              >
                                <Col>
                                  <Row>
                                    {' '}
                                    {result.Price.toString().replace(
                                      /\D/g,
                                      ''
                                    ) == 0 ? (
                                      ' Precio No Disponible'
                                    ) : (
                                      <strong>{`$${new Intl.NumberFormat(
                                        'es-CL'
                                      ).format(
                                        result.Price.toString().replace(
                                          /\D/g,
                                          ''
                                        )
                                      )}`}</strong>
                                    )}
                                  </Row>
                                  <Row>
                                    {' '}
                                    <small>
                                      Haz Click para ir al sitio del Producto.
                                    </small>
                                  </Row>
                                </Col>
                              </Card>
                            ) : (
                              <Card
                                onClick={() => window.open(result.Link)}
                                hoverable={true}
                                bordered={false}
                                style={{
                                  marginBottom: 10,
                                  marginTop: 10,
                                  background: 'none',
                                  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 12px',
                                }}
                                headStyle={{
                                  fontSize: 'small',
                                  backgroundColor: 'rgb(22,119,255)',
                                  color: 'white',
                                }}
                                title={result.Name}
                              >
                                <Col>
                                  <Row>
                                    {' '}
                                    {result.Price.toString().replace(
                                      /\D/g,
                                      ''
                                    ) == 0 ? (
                                      'No Disponible'
                                    ) : (
                                      <strong>{`$${new Intl.NumberFormat(
                                        'es-CL'
                                      ).format(
                                        result.Price.toString().replace(
                                          /\D/g,
                                          ''
                                        )
                                      )}`}</strong>
                                    )}
                                  </Row>
                                  <Row>
                                    {' '}
                                    <small>
                                      Haz Click para ir al sitio del Producto.
                                    </small>
                                  </Row>
                                </Col>
                              </Card>
                            )}
                          </Col>
                        </Row>
                      )
                    })}{' '}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',

                      alignItems: 'center',
                    }}
                  >
                    <img src={pill_1F48A} alt="" style={{ width: 32 }} />
                    <strong>{chain.name}</strong>
                    <small>{chain.arrayData.at(0).Date}</small>
                  </div>
                </Card>
              </Col>
            ))}
        </Row>
        <p>
          Todos los valores son obtenidos directamente de los sitios oficiales
          de las cadenas. Valores sujetos a promoción no se muestran en este
          sitio.
        </p>
      </Card>
    </div>
  )
}

export default Drugs
