import React from 'react'
import { useState, useEffect } from 'react'
import perritos_perdidos_santiago_chile_df_data from '../scrapData/Pets/V1/perritos_perdidos_santiago_chile_df_data.json'
import perritos_perdidos_y_encontrados_en_maipu_df_data from '../scrapData/Pets/V1/perritos_perdidos_y_encontrados_en_maipu_df_data.json'
import perros_perdidos_y_encontrados_la_florida_df_data from '../scrapData/Pets/V1/perros_perdidos_y_encontrados_la_florida_df_data.json'
import solo_mascotas_perdidas_o_encontradas_df_data from '../scrapData/Pets/V1/solo_mascotas_perdidas_o_encontradas_df_data.json'
import sos_perritos_perdidos_en_chile_oficial_df_data from '../scrapData/Pets/V1/sos_perritos_perdidos_en_chile_oficial_df_data.json'
import sos_perritos_perdidos_y_encontrados_df_data from '../scrapData/Pets/V1/sos_perritos_perdidos_y_encontrados_df_data.json'
import perros_perdidos_santiago_df_data from '../scrapData/Pets/V1/perros_perdidos_santiago_df_data.json'
import './wall.css'
import { Row, Col, Card, Button } from 'antd'
import { Tabs } from 'antd'
import { initializeApp } from 'firebase/app'
import { getDatabase, set, onValue, remove } from 'firebase/database'
import { ref as dbRef } from 'firebase/database'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_key,
  authDomain: 'hextoolsperdidos.firebaseapp.com',
  projectId: 'hextoolsperdidos',
  storageBucket: 'hextoolsperdidos.appspot.com',
  databaseURL: 'https://hextoolsperdidos-default-rtdb.firebaseio.com',
  messagingSenderId: '906699436229',
  appId: '1:906699436229:web:90a011162ba0a0db70c0ac',
  measurementId: 'G-50X64PB86Z',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

const db = getDatabase(app)

const Wall = () => {
  const [activeKey, setActiveKey] = useState('1')
  const [dbData, setDbData] = useState('not-loaded')
  const [groups, setGroups] = useState([])

  const onChange = (key) => {
    setActiveKey(key.toString())
    console.log('key', key)
  }

  useEffect(() => {
    readData()
  }, [])

  const perros_perdidos_santiago_array = []
  const sos_perritos_perdidos_y_encontrados_array = []
  const sos_perritos_perdidos_en_chile_oficial_array = []
  const solo_mascotas_perdidas_o_encontradas_array = []
  const perros_perdidos_y_encontrados_la_florida_array = []
  const perritos_perdidos_y_encontrados_en_maipu_array = []
  const perritos_perdidos_santiago_chile_array = []

  /*
  const groups = [
    {
      children: perros_perdidos_santiago_array,
    },
    {
      children: sos_perritos_perdidos_y_encontrados_array,
    },
    {
      children: sos_perritos_perdidos_en_chile_oficial_array,
    },
    {
      children: solo_mascotas_perdidas_o_encontradas_array,
    },
    {
      children: perros_perdidos_y_encontrados_la_florida_array,
    },
    {
      children: perritos_perdidos_y_encontrados_en_maipu_array,
    },
    {
      children: perritos_perdidos_santiago_chile_array,
    },
  ]
*/
  const facebookData = dbRef(db, 'facebook/')
  const arr = []
  const jsonArr = []
  const emptyGoup = []
  function readData() {
    onValue(facebookData, (snapshot) => {
      const data = snapshot.val()
      console.log('facebookData', Object.values(data))
      if (data == null) return
      Object.entries(data)?.map((entry) => arr.push(entry[1]))
      for (let i = 0; i < arr.length; i++) {
        jsonArr.push(JSON.parse(arr[i]))
      }

      for (let i = 0; i < jsonArr.length; i++) {
        const child = []
        Object.entries(jsonArr.at(i)).forEach((entry) => {
          child.push(entry.at(1))
        })
        emptyGoup.push(child)
      }
      setGroups([...emptyGoup])
      setDbData('Loaded')
    })
  }
  return (
    <div>
      <Row>
        <Col>
          <div style={{ marginLeft: 100, marginRight: 100, marginBottom: 20 }}>
            {' '}
            <h2>Muro Perdidos</h2>
            <p>
              Esta sección recompila las entradas más recientes de múltiples
              grupos de Facebook dedicados al extravío de mascotas,
              principalmente perros.
            </p>
            <p>¿Por que no se agregan estos perros al mapa interactivo?</p>
            <small>
              La idea del mapa es generar una fuente actualizada y
              estandarizada, cosa que es difícil de lograr con los resultados de
              los grupos de Facebook, ya que hay mucha data repetida, incompleta
              o que no se ajusta a un formato estandarizado. Además generalmente
              incluye información personal como números telefónicos o correos.
              Estos datos debiesen ser ingresados por la persona con su cuenta
              personal de Gmail, de manera voluntaria.
            </small>
          </div>
        </Col>
      </Row>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {dbData == 'Loaded' &&
          groups.map((group, index) => {
            return (
              <Card
                title={
                  <small style={{ padding: 10 }}>
                    {group.at(0).Group.charAt(0).toUpperCase() +
                      group.at(0).Group.slice(1).toLowerCase()}
                  </small>
                }
                headStyle={{
                  backgroundColor: 'rgb(22, 119, 255)',
                  color: 'white',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <small style={{ padding: 10 }}>
                    {group.at(0).Group.charAt(0).toUpperCase() +
                      group.at(0).Group.slice(1).toLowerCase()}
                  </small>
                  <small style={{ padding: 10 }}>{`Actulización: ${
                    group.at(0).Date
                  }`}</small>
                  <small style={{ padding: 10 }}>
                    {' '}
                    {group.at(0)['Members']}
                  </small>

                  <Button href={group.at(0)['Group Link']} target="_blank">
                    Ir al Facebook del Grupo
                  </Button>
                  <Button type="primary" onClick={(e) => onChange(index + 1)}>
                    Ver Muro
                  </Button>
                </div>
              </Card>
            )
          })}
      </Row>

      <Tabs
        defaultActiveKey="1"
        activeKey={activeKey}
        onChange={onChange}
        items={
          dbData == 'Loaded' &&
          groups.map((group, index) => {
            return {
              label: '',
              key: `${index + 1}`,
              children: (
                <div className="grid-container">
                  {group.slice(1, -1).map((perro) => (
                    <div className="container">
                      <a href={perro.Link} target="_blank">
                        <p className="centered">Ir al Post de Facebook</p>
                        <img src={perro.Image} className="imageCell" />
                      </a>
                    </div>
                  ))}
                </div>
              ),
            }
          })
        }
      />
    </div>
  )
}

export default Wall
