import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Row, Col } from 'antd'
import './navbar.css'

const Navbar = () => {
  return (
    <div className="navbar">
      {' '}
      <h1 style={{ padding: 5 }}>ElConejo.Info</h1>
      <small>v.0.5.0</small>
      <Row
        style={{
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          marginBottom: 10,
        }}
      >
        <Col style={{ padding: 5 }}>
          {' '}
          <Link style={{ padding: 5 }} to="/">
            <Button
              type="dashed"
              style={{ fontFamily: "'Oswald', sans-serif" }}
            >
              💊 Buscar Remedios
            </Button>
          </Link>
        </Col>
        <Col>
          {' '}
          <Link style={{ padding: 5 }} to="/perdidos">
            <Button
              type="dashed"
              style={{ fontFamily: "'Oswald', sans-serif" }}
            >
              📌 Mapa Perdidos
            </Button>
          </Link>
        </Col>
        <Col>
          {' '}
          <Link style={{ padding: 5 }} to="/muro_perdidos">
            <Button
              type="dashed"
              style={{ fontFamily: "'Oswald', sans-serif" }}
            >
              🐶 Muro Perdidos
            </Button>
          </Link>
        </Col>
        <Col>
          {' '}
          <Link style={{ padding: 5 }} to="/amigos">
            <Button
              type="dashed"
              style={{ fontFamily: "'Oswald', sans-serif" }}
            >
              🐇 Amigos del Conejo
            </Button>
          </Link>
        </Col>
      </Row>
    </div>
  )
}

export default Navbar
