import './App.css'
import Drugs from './components/Drugs'
import LostDogs from './components/LostDogs'
import Amigos from './components/Amigos'
import Navbar from './components/Navbar'
import Wall from './components/Wall'
import { Routes, Route } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Drugs />} />
          <Route path="/perdidos" element={<LostDogs />} />
          <Route path="/amigos" element={<Amigos />} />
          <Route path="/muro_perdidos" element={<Wall />} />
        </Routes>
      </div>
    </div>
  )
}

export default App
